// extracted by mini-css-extract-plugin
export var article = "_blog-post-module--article--d9b84";
export var authorCredits = "_blog-post-module--author-credits--84fc0";
export var content = "_blog-post-module--content--18264";
export var divider = "_blog-post-module--divider--1a086";
export var headerImage = "_blog-post-module--header-image--3e8f5";
export var infoPanel = "_blog-post-module--info-panel--5ee76";
export var navIcon = "_blog-post-module--nav-icon--28c66";
export var publishDetails = "_blog-post-module--publish-details--7ea55";
export var relatedBlogsContainer = "_blog-post-module--related-blogs-container--6baa4";
export var relatedBlogsGrid = "_blog-post-module--related-blogs-grid--cc9cd";
export var requestDemoBanner = "_blog-post-module--request-demo-banner--cf369";
export var socialContainer = "_blog-post-module--social-container--fcda4";
export var title = "_blog-post-module--title--060ae";