import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage as getGatsbyImage } from 'gatsby-plugin-image';

import { getBlogImage } from '@uq-helpers';
import { UqPage, UqLayoutV2, UqHeroV2, UqContainerV2, UqNavSocial, UqResourceCard, UqRequestDemoBanner, UqContentful, UqAppBarV2, UqNavbarV2, UqFooterV2 } from '@uq-components';
import { ImageShape } from '../../models/media';

import * as styles from './_blog-post.module.scss';

const BASE_URL = '/blog';

UqBlogPost.propTypes = {
  data: PropTypes.shape({
    contentfulBlogPost: PropTypes.shape({
      articleBody: PropTypes.shape({
        raw: PropTypes.string,
      }),
      author: PropTypes.string.isRequired,
      coverImage: ImageShape,
      excerpt: PropTypes.string,
      publishDate: PropTypes.string.isRequired,
      thumbnail: ImageShape,
      title: PropTypes.string.isRequired,
    }),
    relatedPosts: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          author: PropTypes.string,
          categories: PropTypes.arrayOf(PropTypes.string),
          coverImage: ImageShape,
          publishDate: PropTypes.string,
          excerpt: PropTypes.string,
          slug: PropTypes.string,
          thumbnail: ImageShape,
          title: PropTypes.string,
        }),
      ),
    }),
  }),
};

export default function UqBlogPost(props) {
  const post = props.data.contentfulBlogPost;
  const content = JSON.parse(post.articleBody.raw);

  const relatedPosts = props.data.relatedPosts.edges;

  return (
    <UqPage
      config={{
        seo: {
          description: post.excerpt,
          src: post.coverImage.url,
          title: post.title,
        },
      }}
    >
      <UqLayoutV2>
        <UqAppBarV2>
          <UqNavbarV2 />
        </UqAppBarV2>

        <UqHeroV2>
          <article className={styles.article}>
            <UqContainerV2>

              <h1 className={styles.title}>
                {post.title}
              </h1>

              <section className={styles.infoPanel}>
                <section className={classNames('caption-1', styles.publishDetails)}>
                  <span>{post.publishDate}</span>
                  <div className={styles.divider} />
                  <span>By {post.author}</span>
                </section>

                {renderSocialLinks()}
              </section>

              <span className={styles.headerImage}>
                <GatsbyImage
                  image={getGatsbyImage(getBlogImage(post))}
                  alt={post.title}
                  src={getBlogImage(post).url}
                  loading="eager"
                />
              </span>

              <section className={styles.content}>
                <UqContentful.BlogContent
                  article={content}
                  assetsRefs={post.articleBody.assetsRefs}
                />
              </section>

              {renderSocialLinks()}
            </UqContainerV2>
          </article>
        </UqHeroV2>

        <UqContainerV2
          className={styles.relatedBlogsContainer}
          fill
        >
          <section className={styles.content}>
            <h2>Related Resources</h2>
            <article className={classNames(styles.content, styles.relatedBlogsGrid)}>
              {relatedPosts.map((v, index) => {
                return (
                  <UqResourceCard
                    key={index}
                    coverImage={getBlogImage(v.node)}
                    createdDate={v.node.publishDate}
                    thumbnail={getBlogImage(v.node)}
                    title={v.node.title}
                    type="blog"
                    url={`${BASE_URL}/${v.node.slug}`}
                  />
                );
              })}
            </article>
          </section>

          <UqRequestDemoBanner
            classes={{
              root: styles.requestDemoBanner,
              row: styles.requestDemoBanner,
            }}
          />
        </UqContainerV2>

        <UqFooterV2 />
      </UqLayoutV2>
    </UqPage>
  );

  function renderSocialLinks() {
    return (
      <article className={styles.socialContainer}>
        <UqNavSocial classes={{ icon: styles.navIcon }} />
      </article>
    );
  }
}

export const pageQuery = graphql`
  query($id: String!, $categories: [String]) {
    contentfulBlogPost: contentfulBlogPost(id: { eq: $id }) {
      articleBody {
        raw
        assetsRefs: references {
          ... on ContentfulAsset {
            __typename
            contentful_id
            gatsbyImageData(
              formats: [AVIF, WEBP]
              placeholder: BLURRED
              width: 800
            )
            publicUrl
            url
          }
        }
      }
      author
      categories
      coverImage {
        gatsbyImageData(
          formats: [AVIF, WEBP]
          placeholder: BLURRED
          width: 800,
        )
        url
      }
      publishDate(formatString: "ll")
      excerpt
      id
      seoDescription
      seoTitle
      slug
      thumbnail {
        gatsbyImageData(
          formats: [AVIF, WEBP]
          placeholder: BLURRED
          width: 800,
        )
        url
      }
      title
    }
    relatedPosts: allContentfulBlogPost(
      filter: {
        id: { ne: $id }
        categories:  {in: $categories}
      }
      sort: { fields: publishDate, order: DESC }
      limit: 3
    ) {
      edges {
        node {
          author
          categories
          coverImage {
            gatsbyImageData(
              formats: [AVIF, WEBP]
              placeholder: BLURRED
              width: 389,
            )
            url
          }
          publishDate(formatString: "ll")
          excerpt
          slug
          thumbnail {
            gatsbyImageData(
              formats: [AVIF, WEBP]
              placeholder: BLURRED
              width: 800,
            )
            url
          }
          title
        }
      }
    }
  }
`;
